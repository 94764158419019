import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Menu from '../Menu';
import { useHistory } from 'react-router';
import LoginModal from 'features/LoginModal';
import { LanguageContext } from 'components/Language/Context';
import { useLoggedIn, useConfig } from 'utils/hooks';
import { OEMList } from 'types';
import NavMenu from './Menu';

import logo from 'assets/r2r_logo_new.png';

const LoginLink = styled(Link)<{ $data: OEMList }>`
  color: ${(props) => props.$data.fontcolour};
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
`;

const Head = styled.header<{ $data: OEMList }>`
  font-family: 'mmcbold', sans-serif;
  background-color: ${(props) => props?.$data?.backgroundcolour};
  color: ${(props) => props?.$data?.fontcolour};
`;

const LogoContainer = styled.div<any>`
  align-items: center;
`;

const LogoImage = styled.img<any>`
  display: block;
  height: 80px;
  width: auto;
  cursor: pointer;
`;

const LogoName = styled.div<any>`
  line-height: 4.85rem;
  font-size: 25px;
  font-family: mmcbold;
  padding-left: 1rem;
`;

const MenuContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const MenuWrapper = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  font-family: mmcregular;
  color: ${(props) => props.$defaults?.fontcolour};
  a {
    color: ${(props) => props.$defaults?.fontcolour};
  }
  span {
    font-family: mmcregular;
  }
  span.nav-active {
    border-bottom: 1px solid ${(props) => props.$defaults?.fontcolour};
  }
`;

const ALink = styled<any>(Link)`
  color: ${(props) => {
    return props.$defaults?.fontcolour || 'inherit';
  }};
`;

function Generic() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const { user: authUser } = useLoggedIn(true, true);
  const { messages } = useContext(LanguageContext);
  const { defaults } = useConfig();

  const history = useHistory();

  const client = window.location.pathname.split('/')[1];

  useEffect(() => {
    if (!defaults) return;
    const fav: any = document.getElementById('favicon');
    console.log('FAVICON', client, defaults?.faviconurl);
    if (defaults?.faviconurl && client) fav.href = defaults?.faviconurl;
    else fav.href = '/public/favicon.ico';
  }, [defaults, client]);

  // console.log('MENU :::::', defaults);
  // if (!defaults) return null;

  const navigationHome = async (path?: string) => {
    await history.push(`/${client}`);
  };

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <>
      {showLoginModal && (
        <LoginModal client={client} close={() => setShowLoginModal(false)} />
      )}
      <Head id="r2r-header" className="w-full flex p-3" $data={defaults}>
        <LogoContainer className="flex justify-start w-full pl-8">
          <LogoImage
            src={client === 'r2r' ? logo : defaults?.websitelogo}
            alt={defaults?.Name}
            onClick={() => {
              navigationHome();
            }}
          />
          {client === 'mmal' || client === 'r2r' ? (
            <LogoName
              className="grow text-4xl pl-2 flex self-center"
              style={{ lineHeight: '25px' }}
            >
              {defaults?.headerOemName}
            </LogoName>
          ) : (
            <LogoName>{defaults?.headerOemName}</LogoName>
          )}
        </LogoContainer>
        {authUser?.email ? (
          <MenuContainer>
            <MenuWrapper $defaults={defaults}>
              <NavMenu
                showAssetMenuState={showMenu}
                showAssetMenu={toggleMenu}
              />
            </MenuWrapper>
            <button className="p-3 pb-3" onClick={() => toggleMenu()}>
              <span className="fas fa-bars" />
            </button>
          </MenuContainer>
        ) : (
          <div className="flex justify-end w-full items-center pr-3">
            <div className="flex  p-6" style={{ whiteSpace: 'nowrap' }}>
              <span
                className="text-center text-xxs font-normal font-sans mr-10"
                style={{
                  fontSize: '16px',
                  fontFamily: 'mmcregular',
                  color: defaults?.fontcolor,
                }}
              >
                <ALink $defaults={defaults} to={`/${client}/signup`}>
                  {messages.signup}
                </ALink>
              </span>
              <span
                className="text-center text-xxs font-normal font-sans"
                style={{
                  fontSize: '16px',
                  fontFamily: 'mmcregular',
                  color: defaults?.fontcolor,
                }}
              >
                <button
                  onClick={() => setShowLoginModal(true)}
                  style={{ outline: 0 }}
                >
                  {messages.login}
                </button>
                {/* <ALink $defaults={defaults} to={`/${client}/login`}>
                  {messages.login}
                </ALink> */}
              </span>
            </div>
          </div>
        )}
        {authUser?.email && (
          <Menu
            showMenu={showMenu}
            setShowMenu={toggleMenu}
            messages={messages}
          />
        )}
      </Head>
    </>
  );
}

export default Generic;
